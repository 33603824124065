import React, { useState, useEffect } from "react";
import { Form, Input } from "antd";
import $ from "jquery";
import { Button, Modal } from "antd";
import EventJson from "../Components/Event/EventData";
import EnquiryModal from "../Components/EnquiryModal.jsx";
import { useLocation } from "react-router-dom";

const CheckMenu = () => {
  const search = useLocation();
  const path = search.pathname;
  const [form] = Form.useForm();
  // -------------------selected-----------
  const [selectedData, setSelectedData] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [modalShow, setModalShow] = React.useState(false);
  const [foodList, setFoodList] = useState([]);

  const handleClick = (services) => {
    setFoodList(
      selectedData.Menu.find((a, i) => a.Header === services)?.ServicesHeading1
    );
    setSelectedCategory(services);
  };

  const [selectedItems, setSelectedItems] = useState([]);

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedItems((prevItems) => [...prevItems, value]);
    } else {
      setSelectedItems((prevItems) =>
        prevItems.filter((item) => item !== value)
      );
    }
  };

  useEffect(() => {
    const EventsF = EventJson.find((e) => e.slug === path);
    setSelectedData(EventsF);
    setFoodList(EventsF.Menu[0]?.ServicesHeading1);
    setSelectedCategory(EventsF.Menu[0]?.Header);
  }, [path]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [selectedCheckItems, setSelectedCheckItems] = useState([]);

  const handleCheckbox = (title, price) => {
    console.log(title, price);
    const item = { title, price, category: selectedCategory };
    console.log(item);
    setSelectedCheckItems((prevSelectedItems) => {
      const isSelected = prevSelectedItems.some(
        (i) => i.title === item.title && i.category === item.category
      );
      if (isSelected) {
        return prevSelectedItems.filter(
          (i) => !(i.title === item.title && i.category === item.category)
        );
      } else {
        return [...prevSelectedItems, item];
      }
    });
  };
  console.log(selectedCheckItems);
  const success = () => {
    Modal.success({
      content: "Your Form has Submitted Our team will contact with You  soon.",
    });
  };
  const selectedItemsHTML = selectedCheckItems
    .map(
      (item, i) =>
        `<tr><td>${i + 1} ${item.title}</td><td>${item.price}</td><td>${
          item.category
        }</td></tr>`
    )
    .join("");
  // const ContactEnquiry = (e) => {
  const submitForm = (values) => {
    console.log("values", values);

    var body =
      '<!DOCTYPE html><html><head><title>Order Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#dc9d7e">The Udupi Palace</h2><p>Hello</p><p>Thank you for your interest in our products/services.</p><p>Please check your order which generated from website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left;color:#dc9d7e"><strong>Name:</strong></td><td style="text-align:left">' +
      values.name +
      '</td></tr><tr><td style="text-align:left;color:#dc9d7e"><strong>Email:</strong></td><td style="text-align:left">' +
      values.email +
      '</td></tr><tr><td style="text-align:left;color:#dc9d7e"><strong>Message:</strong></td><td style="text-align:left">' +
      values.message +
      '</td></tr><tr><td style="text-align:left;color:#dc9d7e"><strong>Mobile Number:</strong></td><td style="text-align:left">' +
      values.mobileNo +
      '</td></tr><tr><td style="text-align:left;color:#dc9d7e"><strong>Order for Food In trays :</strong></td><td style="text-align:left">' +
      selectedItemsHTML +
      '</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at The Udupi Palace</p></div></body></html>';

    $.post(
      "https://skdm.in/server/v1/send_lead_mail.php",
      {
        toEmail: "udupipalace09@gmail.com",
        fromEmail: "skdmlead@gmail.com",
        bccMail: "skdmlead@gmail.com",
        mailSubject: "New Customer Lead",
        mailBody: body,
        accountName: "Omkar nature farm",
        accountLeadSource: "omkarnaturefarm.in",
        accountLeadName: values.name,
        accountLeadEmail: values.email,
        accountLeadPhone: values.contact,
      },

      function (dataa, status) {
        console.log("data :" + dataa);
        console.log("name:" + values);
      }
    );
    handleOk();
    success();
    // alert("Your Form has Submitted Our team will contact with You  soon.");
    values.preventDefault();
    // values.reset()
    form.resetFields();
    setSelectedCheckItems([]);

    return false;
  };
  return (
    <div>
      {/* ========= Services Details */}
      <section className="service-single-area py-5">
        <div className="container">
          {selectedData && selectedData.Menu.length > 0 ? (
            <div className="service-single-wrapper">
              <div className="row">
                <div className="col-xl-3 col-lg-3">
                  <div className="service-sidebar">
                    <div className="widget category">
                      <h4 className="widget-title">All Menus</h4>
                      <div className="category-list">
                        {selectedData.Menu.map((services, index) => (
                          <button
                            key={index}
                            className={`nav-link ${
                              selectedCategory === services.Header
                                ? "active"
                                : ""
                            }`}
                            onClick={() => handleClick(services.Header)}
                          >
                            <i className="fa-solid fa-arrow-right"></i>
                            {services.Header.toUpperCase()}
                          </button>
                        ))}
                      </div>
                    </div>
                    {/* Other components or elements */}
                  </div>
                </div>
                <div className="col-xl-9 col-lg-9">
                  <div className="service-details">
                    <div className="service-detail">
                      <h3 className="mb-30">
                        {selectedCategory && (
                          <h4>
                            {" "}
                            <span>{selectedCategory.toUpperCase()} <span style={{textTransform:"none", color:"#808080"}}>(Prices are per tray)</span></span>{" "}
                            {selectedCheckItems.length > 0 ? (
                              <Button
                                type="primary"
                                className="selected-all-checkBoxBtn"
                                onClick={showModal}
                              >
                                Order Now
                              </Button>
                            ) : (
                              ""
                            )}
                          </h4>
                        )}

                        <Modal
                          title="Order Now"
                          type="danger"
                          open={isModalOpen}
                          onOk={handleOk}
                          onCancel={handleCancel}
                          footer={null}
                        >
                          <Form
                            action="#"
                            className="php-email-form"
                            layout="vertical"
                            onFinish={submitForm}
                          >
                            <div className="reserve-form-top">
                              <Form.Item
                                name="name"
                                label="Name"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter your name!",
                                  },
                                ]}
                              >
                                <Input
                                  placeholder="Enter a name"
                                  className="form-control custName custNameValidation"
                                />
                              </Form.Item>
                              <Form.Item
                                name="email"
                                label="Email"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter email!",
                                  },
                                ]}
                              >
                                <Input
                                  placeholder="Enter e-mail id"
                                  className="form-control custEmail custEmailValidation"
                                />
                              </Form.Item>
                              <Form.Item
                                name="mobileNo"
                                label="mobileNo"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter mobile:no!",
                                  },
                                ]}
                              >
                                <Input
                                  placeholder="Enter mobile:no"
                                  className="form-control custEmail custEmailValidation"
                                />
                              </Form.Item>
                              {/* <div className="date-time ptb-10">
                          <select>
                            <option selected="">Select Date And Time</option>
                            <option>26Dec-10.00pm</option>
                            <option>2Jan-10.00pm</option>
                            <option>17Dec-10.00pm</option>
                          </select>
                        </div> */}
                              <Form.Item
                                name="message"
                                label="Message"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Your message!",
                                  },
                                ]}
                              >
                                <Input.TextArea
                                  className="form-control custMessage"
                                  placeholder="Enter message"
                                />
                              </Form.Item>

                              {/* <div className="date-time ptb-10">
                          <select>
                            <option selected="">Select Service</option>
                            <option>Event Booking</option>
                            <option>Bulk Food</option>
                            <option>Tiffin</option>
                          </select>
                        </div> */}
                              <div className="my-2">
                                <h4> Selected Food</h4>
                                <ul>
                                  {selectedCheckItems.map((v, i) => (
                                    <li key={i} style={{ listStyle: "none" }}>
                                      {i + 1} {v.title} ({v.category}){" "}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                            <div className="reserve-submit ">
                              <Form.Item>
                                <Button
                                  className="pb-1 service-selected-check-box"
                                  htmlType="submit"
                                >
                                  submit
                                </Button>
                              </Form.Item>
                              {/* <button type="submit">
                          submit
                        </button> */}
                            </div>
                          </Form>
                        </Modal>
                      </h3>
                      <div className="container">
                        <div className="w-100 d-flex justify-content-start align-items-center">
                          {" "}
                          <strong
                            style={{
                              fontSize: ".9rem",
                              fontWeight: "bold",
                              color: "red",
                            }}
                          >
                            Note:{" "}
                          </strong>
                          <span style={{ fontSize: ".9rem" }}>
                            &nbsp;Please select the checkbox to make an order
                            online.
                          </span>
                        </div>
                      </div>
                      <div className="row menu_style1">
                        <div
                          className="foodItems"
                          style={{
                            display:
                              selectedItems.length > 0 ? "block" : "none",
                          }}
                        >
                          <h4>You have selected these Items:</h4>

                          <ul className="checkList">
                            {selectedItems.map((item, index) => (
                              <li key={index}>{item.toUpperCase()}</li>
                            ))}
                          </ul>
                          <div>
                            {/* <a>Order Now */}
                            <EnquiryModal
                              show={modalShow}
                              // donationValue={donationValue.Tvalue}

                              onHide={() => setModalShow(false)}
                            />
                          </div>
                        </div>
                        {foodList &&
                          foodList?.map((v, i) => (
                            <div className="col-md-6" key={i}>
                              <div className="single_menu_list">
                                <div className="menu_content">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      defaultValue=""
                                      id=""
                                      checked={selectedCheckItems.some(
                                        (item) =>
                                          item.title === v.title &&
                                          item.category === selectedCategory
                                      )}
                                      onChange={() =>
                                        handleCheckbox(v.title, v.price)
                                      }
                                    />
                                    <h4>
                                      {v.title} <span>{v.price}</span>
                                    </h4>
                                  </div>

                                  <p>{v.desc}</p>
                                </div>
                              </div>
                              {/* <div className="single_menu_list">
                                <div className="menu_content">
                                
                                  <h4>
                                    {v.title} <span>{v.price} </span>
                                  </h4>
                                  <p>{v.desc}</p>
                                </div>
                              </div> */}
                            </div>
                          ))}
                      </div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <p></p>
          )}
          {/* Other components or elements */}
        </div>
      </section>
    </div>
  );
};

export default CheckMenu;
